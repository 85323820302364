<template>
  <div v-if="assemblingQueue">
    <action-header
      :actions-model="headerModel"
      :page="page"
    />

    <q-card class="q-ma-sm">
      <q-card-section class="row items-center text-subtitle1 border-bottom">
        <div class="col row items-center">
          <div class="col-12 col-md-4">
            <span class="text-bold">{{ $t('Queue No') + ': ' }}</span>

            <span>{{ assemblingQueue.id }}</span>
          </div>

          <div class="col-12 col-md-8">
            <span class="text-bold">{{ $t('For') + ': ' }}</span>

            <span>{{ assemblingQueue.adapter }}</span>
          </div>

          <div class="col-12 col-md-4">
            <div>
              <strong>
                {{ $t('Name') + ': ' }}
              </strong>

              <span class="d-inline-block">{{ assemblingQueue.name || $t('none') }}</span>

              <q-icon
                name="edit"
                class="q-ml-sm"
              />

              <q-popup-edit
                :model-value="assemblingQueue.name"
                :title="$t('Edit name')"
              >
                <q-input
                  :model-value="assemblingQueue.name"
                  dense
                  autofocus
                  @update:model-value="handleNameChange"
                />

                <div class="q-py-sm text-center">
                  <q-btn
                    color="light-blue-9"
                    :label="$t('Save')"
                    @click="handleSave"
                  />
                </div>
              </q-popup-edit>
            </div>
          </div>

          <div class="col-12 col-md-8">
            <span class="text-bold">{{ $t('On the warehouse') + ': ' }}</span>

            <span>{{ assemblingQueue._embedded.warehouse.name }}</span>
          </div>
        </div>

        <div class="text-right">
          <q-btn
            color="dark"
            icon="history"
            size="sm"
            class="q-mr-sm"
            round
            @click="openHistory"
          />

          <q-btn
            v-if="hasSettingsAndFilters"
            color="dark"
            icon="filter_alt"
            size="sm"
            class="q-mr-sm"
            round
            @click="openFilter"
          />

          <q-btn
            v-if="hasSettingsAndFilters"
            color="dark"
            icon="settings"
            size="sm"
            round
            @click="handleSettingsOpen"
          />
        </div>
      </q-card-section>

      <q-card-section class="q-pa-none">
        <tasks-table
          v-if="assemblingQueue"
          ref="tasksTable"
          :service="tasksService"
          :queue="assemblingQueue.id"
        />
      </q-card-section>
    </q-card>

    <q-dialog v-model="historyModal">
      <q-card style="min-width: 65vw;">
        <q-card-section class="row items-center q-pb-none">
          <div class="text-h6">
            {{ $t('History') }}
          </div>

          <q-space />

          <q-btn
            v-close-popup
            icon="close"
            flat
            round
            dense
          />
        </q-card-section>

        <q-card-section v-if="assemblingQueue">
          <history
            :id="assemblingQueue.id"
            :entity="'Orderadmin\\Storage\\Entity\\Assemblage\\Queue'"
          />
        </q-card-section>
      </q-card>
    </q-dialog>

    <queue-filter-modal
      ref="filterModal"
      @submit="handleSubmit"
    />

    <queue-settings-modal
      ref="settingsModal"
      @submit="handleSubmit"
    />
  </div>
</template>

<script>
// Vuex
import { mapGetters, mapActions, mapMutations } from 'vuex'

// Components
import TasksTable from '../../components/dynamic-components/tasks-table.vue'
import QueueSettingsModal from '../../components/modals/QueueSettingsModal.vue'
import History from '../../components/history/history.vue'
import QueueFilterModal from '../../components/modals/QueueFilterModal.vue'
import ActionHeader from '../../components/action-header/ActionHeader.vue'

export default {
  name: 'AssemblingQueue',
  components: {
    TasksTable,
    QueueSettingsModal,
    History,
    QueueFilterModal,
    ActionHeader
  },
  data () {
    return {
      historyModal: false,
      tab: 'manage',
      splitterModel: 20,
      statistics: [],
      isExpanded: true,
      tasksService: this.$service.assemblingTask,
      adaptersWithSettings: ['shortAssembling', 'parallelAssembling', 'task']
    }
  },
  computed: {
    ...mapGetters([
      'assemblingQueue',
      'appOptions'
    ]),
    hasSettingsAndFilters () {
      return true
    },
    page () {
      return {
        id: this.assemblingQueue && this.assemblingQueue.id,
        name: this.$t('Packing Manager')
      }
    },
    headerModel () {
      return [
        {
          section: 'BackAction',
          className: 'row items-center justify-start',
          options: [
            {
              id: 'back',
              type: 'button',
              icon: 'arrow_back',
              variant: 'light',
              style: 'white-space: nowrap;',
              wrapperClassName: 'q-mr-sm',
              label: this.$t('Back'),
              onClick: this.handleBack
            },
            {
              id: 'portal',
              type: 'portal',
              name: 'queue-header'
            },
            {
              id: 'title',
              type: 'text',
              className: 'text-white',
              value: this.$t('Packing Manager')
            }
          ]
        }
      ]
    }
  },
  mounted () {
    Promise.resolve(!!this.assemblingQueue && this.$route.params.id === this.assemblingQueue.id)
      .then(hasQueue => {
        return hasQueue
          ? this.assemblingQueue
          : this.loadAssemblingQueue(this.$route.params.id)
      })
      .then(() => {
        if (!this.hasSettingsAndFilters) {
          return
        }

        this.$service.assemblingQueue.getSettings(this.assemblingQueue.id)
          .then(settings => {
            function getKey (str) {
              return str.split('.')[1]
            }

            const hasRequiredField = settings.find(field => field.required && this.assemblingQueue.settings[getKey(field.model)])

            if (hasRequiredField) {
              this.handleSettingsOpen()
            }
          })
      })
  },
  unmounted () {
    this.setAssemblingQueue(null)
  },
  methods: {
    ...mapActions([
      'loadAssemblingQueue',
      'saveAssemblingQueue'
    ]),
    ...mapMutations([
      'updateAssemblingQueue',
      'setNewAssemblingQueue',
      'setAssemblingQueue'
    ]),
    handleBack () {
      this.$router.back()
    },
    openFilter () {
      this.$refs.filterModal.open(this.assemblingQueue, this.$service.assemblingQueue, 'packing', this.assemblingQueue.id)
    },
    openHistory () {
      this.historyModal = true
    },
    handleSettingsOpen () {
      this.$refs.settingsModal.open(this.assemblingQueue, this.$service.assemblingQueue, {type: 'packing'})
    },
    handleSubmit (queue) {
      this.setAssemblingQueue(queue)
    },
    handleSave () {
      this.$service.assemblingQueue.save({ name: this.assemblingQueue.name }, this.assemblingQueue.id)
        .then(queue => {
          this.setAssemblingQueue(queue)
        })
    },
    handleNameChange (name) {
      this.updateAssemblingQueue({ name })
    }
  }
}
</script>
